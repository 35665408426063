<template>
  <div>
    <el-steps :active="activeStep" align-center class="u-bottom-margin--5x">
      <el-step v-for="step in stepperSteps" :key="step.title" :title="step.title"
        :description="step.description"></el-step>
    </el-steps>
    <product-details-card v-if="activeStep == 1" :ref="productDetailsRefName"
      @product-saved="saveForm"></product-details-card>
    <unit-details-card v-if="activeStep == 2" @units-saved="assignUnitsToProduct"></unit-details-card>
    <div v-if="activeStep == 3">
      <el-card class="success-card" shadow="always" :body-style="{ padding: '20px' }">
        <h2>Product Created successfully</h2>
        <div class="checkout-icon u-top-margin--4x">
          <i class="el-icon-check"></i>
        </div>
        <div class="post-create-actions u-top-margin--4x">
          <el-button icon="el-icon-back
" type="primary" size="default" plain @click="goBack">Back to products</el-button>
          <el-button type="primary" size="default" @click="assignProductToProviders">Assign product to providers
          </el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { productsService } from "../../services/products.service";
import { utils } from "../../utils";
import { constants } from "./components/productComponents.data";
import ProductDetailsCard from "./components/ProductDetailsCard.vue";
import UnitDetailsCard from "./components/UnitDetailsCard.vue";
import { mapGetters } from "vuex";
import { $http } from "@/api";
import { authHeader } from "@/authHeader";

export default {
  name: "AddProductPage",
  components: {
    ProductDetailsCard,
    UnitDetailsCard,
  },
  data() {
    return {
      productDetailsRefName: constants.productDetailsRefName,
      draftData: {
        // name: "",
        draftId: "",
        isDraft: false,
      },
      productObject: {},
      activeStep: 1,
      stepperSteps: [
        {
          title: "Product Creation",
          description: "Create product basic data ",
        },
        {
          title: "Product Units Assignment",
          description: "Assign units to product ",
        },
        {
          title: "Complete",
          description: "",
        },
      ],
    };
  },
  mounted() {
    if (this.draftProductId) {
      // this.draftData.name = this.draftProductName;
      this.draftData.draftId = this.draftProductId;
      this.draftData.isDraft = true;
    }
  },
  computed: {
    draftProductName() {
      return this.$route.query.name;
    },
    draftProductId() {
      return this.$route.query.id;
    },
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },

  methods: {
    goBack() {
      this.$router.push({
        name: "productsList",
      });
    },
    assignProductToProviders() {
      this.$router.push({
        name: "AssignProduct",
        params: { id: this.productObject.id },
      });
    },

    async saveForm(payload) {
      let { imageData, productObject } = payload;
      if (this.draftProductId) {
        productObject = { ...productObject, ...this.draftData };
      }
      try {
        let productRes = await productsService.addProduct(productObject);
        this.productObject = productRes;
        if (imageData.imageFile) {
          let productFD = new FormData();
          productFD.append("image", imageData.imageFile);
          productFD.append("ids", JSON.stringify([productRes.id]));
          await productsService.bulkEditProductsImages(productFD);
        }

        this.activeStep = 2;

        this.$notify({
          title: this.$t("AddProductPageSuccessNotificationTitle"),
          message: this.$t("AddProductPageAddSuccessNotificationBody"),
          type: "success",
        });
        
        let lookups = localStorage.getItem("storedLookups");
        lookups = JSON.parse(lookups);
        const trademarkLookups = await $http.get("/admin/lookup/trademark", {
          headers: authHeader(),
        });
        const manufacturerLookups = await $http.get("/admin/lookup/manufacturer", {
        headers: authHeader(),
      });
        lookups['trademarks'] = trademarkLookups?.data?.data?.result;
        lookups["manufacturer"] = manufacturerLookups?.data?.data?.result;
        localStorage.setItem("storedLookups", JSON.stringify(lookups));
        this.$store.commit("setLookups", lookups);
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        this.$notify({
          title: this.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    },
    async assignUnitsToProduct(units) {
      const productUnitsObject = {
        productId: this.productObject.id,
        productUnitsDetails: units,
      };
      try {
        await productsService.assignMultipleUnitsOnProduct(productUnitsObject);

        this.$notify({
          title: this.$t("AddProductPageSuccessNotificationTitle"),
          message: this.$t("Units assigned successfully"),
          type: "success",
        });
        this.activeStep = 3;
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        this.$notify({
          title: this.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-icon {
  font-size: 9rem;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #67c23a;
  border-radius: 50%;
  margin: 0 auto;
}

.success-card {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}
</style>
